import {
  borderColor,
  borderRadius,
  color,
  height,
  margin,
  padding,
  textTransform,
  width
} from '@mui/system';

const styles = {
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 0,
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
  loginContent: {
    display: 'block',
    textAlign: 'center',
    width: '100%',
    zIndex: 1000
  },
  error: {
    color: '#FF0000'
  },
  loginBg: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0
  },
  title: {
    fontSize: '80px',
    color: '#FFFFFF',
    display: 'block',
    lineHeight: '100px'
  },
  subTitle: {
    fontSize: '30px',
    color: '#FFFFFF',
    display: 'block',
    marginBottom: '20px'
  },
  fieldContainer: {
    justifyContent: 'center',
    margin: '5px'
  },
  textField: {
    width: '100%',
    marginBottom: '20px'
  },
  input: {
    padding: '10px 0px',
    padding: '0px 12px',
    height: '54px',
    borderRadius: '5px',
    color: 'black'
  },
  'input:-internal-autofill-selected': {
    backgroundColor: 'none'
  },
  'input:-webkit-autofill': {
    backgroundColor: 'none'
  },
  loginBtn: {
    width: '100%',
    fontSize: '20px',
    color: '#FFFFFF',
    margin: '10px',
    borderRadius: '5px',
    textTransform: 'none',
    '&:disabled': {
      backgroundColor: '#b6b6b6'
    }
  },
  loginBox: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  loginWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  cardWrapper: {
    position: 'relative',
    padding: '40px'
  },
  CardActions: {
    flexDirection: 'column',
    padding: '0px'
  },
  CardContent: {
    padding: '0px'
  },
  dividerBox: {
    width: '100%',
    position: 'relative'
  },
  orText: {
    position: 'absolute',
    left: '45%',
    top: '8px',
    padding: '0px 10px',
    zIndex: '99',
    background: 'white'
  },
  divider: {
    borderColor: 'black',
    margin: '20px 100px'
  }
};
export default styles;

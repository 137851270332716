import React from 'react';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import PropTypes from 'prop-types';
import appCss from './styles';
import loginBgImage from '../../assets/images/login-bg.jpg';
import { login } from '../../actions/loginAction';
import MiniSpinner from '../../common/components/Spinner';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TenantDialog from '../dialogs/tenant-dialog';
import {
  MSAL_CONFIG,
  LOGIN_REQUEST,
  TOKEN_REQUEST,
  GRAPH_CONFIG,
  PUBLIC_CLIENT_APPLICATION
} from '../../msalConfiguration';

const divStyle = {
  color: '#FFFFFF',
  fontSize: '15px'
};

class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { username: '', password: '', error: '', tokenAzure: '', openTenantDialog: false };
  }

  login = async loginType => {
    if (loginType === 'user') {
      localStorage.removeItem('tokenAzure');
      try {
        const { username, password } = this.state;
        const { login } = this.props;
        if (!username.length || !password.length) {
          return;
        }
        const data = { username, password };
        login(data);
      } catch (error) {
        console.error('User login failed:', error);
        this.setState({ error: error.message });
      }
    } else if (loginType === 'microsoft') {
      try {
        const loginResponse = await PUBLIC_CLIENT_APPLICATION.loginPopup(LOGIN_REQUEST);
        if (loginResponse.account) {
          PUBLIC_CLIENT_APPLICATION.setActiveAccount(loginResponse.account);
        }
        const tokenResponse = await PUBLIC_CLIENT_APPLICATION.acquireTokenSilent(TOKEN_REQUEST);
        if (tokenResponse?.accessToken) {
          this.setState({ tokenAzure: tokenResponse.accessToken }, () => {
            localStorage.setItem('tokenAzure', this.state.tokenAzure);
            const data = { tokenAzure: this.state.tokenAzure };
            this.props.login(data); // Dispatch the login action
            // this.openTenantDialog();
          });
        } else {
          console.error('Token response was null or empty');
        }
      } catch (error) {
        console.error('Microsoft login failed:', error);
        this.setState({ error: error.message });
      }
    }
  };
  openTenantDialog = () => {
    this.setState({ openTenantDialog: true });
  };
  closeTenantDialog = () => {
    this.setState({ openTenantDialog: false });
  };

  forgotpassword = () => {
    // Handle forgot password logic here
  };

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.login(e);
    }
  };

  render() {
    const { isLoginFailed, isLoading, isLoginFailedAzure } = this.props;
    const { username, password } = this.state;
    const isDisabled = !username || !password || isLoading;
    return (
      <>
        <Box sx={appCss.loginWrapper}>
          <img alt="Login" style={appCss.loginBg} src={loginBgImage} />

          <Box sx={appCss.loginBox}>
            <Typography sx={appCss.title}>SEMS</Typography>
            <Typography sx={appCss.subTitle}>Service Management System</Typography>
          </Box>
          <Box>
            <Card sx={appCss.cardWrapper}>
              <CardContent sx={appCss.CardContent}>
                <Typography gutterBottom style={{ marginBottom: '10px', fontSize: '18px' }}>
                  Welcome to <strong>SEMS</strong>
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  style={{ marginBottom: '20px', fontSize: '13px' }}
                >
                  We just need a few details to get you up and running.
                </Typography>
                <TextField
                  variant="outlined"
                  label="Username"
                  size="small"
                  sx={appCss.textField}
                  id="username"
                  InputProps={{ sx: appCss.input }}
                  value={username}
                  name="username"
                  onChange={this.handleInputChange}
                  placeholder="Enter username"
                  onKeyPress={e => this.handleKeyPress(e)}
                  autoComplete="off"
                  autoFocus
                />
                <TextField
                  size="small"
                  variant="outlined"
                  label="Password"
                  sx={appCss.textField}
                  type="password"
                  id="password"
                  InputProps={{ sx: appCss.input }}
                  value={password}
                  name="password"
                  onChange={this.handleInputChange}
                  placeholder="**********"
                  onKeyPress={e => this.handleKeyPress(e)}
                  autoComplete="off"
                />
                {isLoginFailed && (
                  <Grid container sx={appCss.fieldContainer}>
                    <Typography sx={appCss.error}>Invalid username or password</Typography>
                  </Grid>
                )}
              </CardContent>
              <CardActions sx={appCss.CardActions}>
                <Fab
                  disabled={isDisabled}
                  color="primary"
                  size="large"
                  sx={appCss.loginBtn}
                  variant="extended"
                  onClick={() => this.login('user')}
                >
                  LOGIN
                  {isLoading && <MiniSpinner />}
                </Fab>
                <Box sx={appCss.dividerBox}>
                  <Typography sx={appCss.orText}>OR</Typography>
                  <Divider sx={appCss.divider} />
                </Box>
                <Fab
                  disabled={isLoading}
                  color="primary"
                  size="large"
                  variant="extended"
                  sx={appCss.loginBtn}
                  onClick={() => this.login('microsoft')}
                >
                  Login with Volvo account
                  {isLoading && <MiniSpinner />}
                </Fab>
              </CardActions>
              {isLoginFailedAzure && (
                <Grid container sx={appCss.fieldContainer}>
                  <Typography sx={appCss.error}>
                    You do not have permission to access the SEMS Admin portal.
                  </Typography>
                </Grid>
              )}
            </Card>
          </Box>
          <TenantDialog></TenantDialog>
        </Box>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoginFailed: state.loginReducer.isLoginFailed,
    isLoading: state.loginReducer.isLoading,
    isLoginFailedAzure: state.loginReducer.isLoginFailedAzure
  };
};

Login.propTypes = {
  isLoginFailed: PropTypes.bool,
  login: PropTypes.func,
  isLoginFailedAzure: PropTypes.bool
};

export default connect(mapStateToProps, { login })(Login);

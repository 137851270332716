import { PublicClientApplication, LogLevel } from '@azure/msal-browser';
let hostname = {};
hostname.redirectUri = window.location.origin + '/index.html';

const MSAL_CONFIG = {
  auth: {
    clientId: '213afda2-f313-4901-8b8d-cad9969ce555',
    authority: 'https://login.microsoftonline.com/f25493ae-1c98-41d7-8a33-0be75f5fe603',
    redirectUri: hostname.redirectUri // redirect URI based on domain
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
        }
      }
    }
  }
};

const LOGIN_REQUEST = {
  scopes: ['openid', 'profile', 'User.Read.All']
};

const TOKEN_REQUEST = {
  scopes: ['User.Read.All'],
  forceRefresh: false
};

const GRAPH_CONFIG = {
  graphUsersEndpoint: 'https://graph.microsoft.com/v1.0/users'
};

const PUBLIC_CLIENT_APPLICATION = new PublicClientApplication(MSAL_CONFIG);
async function initializeMsal() {
  await PUBLIC_CLIENT_APPLICATION.initialize();
}
initializeMsal();
function clearCache() {
  try {
    localStorage.clear();
    sessionStorage.clear();
    console.log('Cache cleared successfully');
  } catch (error) {
    console.error('Error clearing cache:', error);
  }
}

export {
  MSAL_CONFIG,
  LOGIN_REQUEST,
  TOKEN_REQUEST,
  GRAPH_CONFIG,
  PUBLIC_CLIENT_APPLICATION,
  clearCache
};

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const REFRESH_CREDENTIALS = 'REFRESH_CREDENTIALS'; //
export const UPDATE_CREDENTIALS = 'UPDATE_CREDENTIALS'; //
export const SHOW_PAGE_LOADING = 'SHOW_PAGE_LOADING'; //
export const HIDE_PAGE_LOADING = 'HIDE_PAGE_LOADING'; //

export const UPDATE_SEMS_CLIENT = 'UPDATE_SEMS_CLIENT';
export const UPDATE_SEMS_TYPE = 'UPDATE_SEMS_TYPE';
export const UPDATE_SEMS_CLIENT_SUCCESS = 'UPDATE_SEMS_CLIENT_SUCCESS';
export const UPDATE_SEMS_CLIENT_ERROR = 'UPDATE_SEMS_CLIENT_ERROR';

export const FETCH_APPLICATIONS = 'FETCH_APPLICATIONS';
export const APPLICATIONS_LOADING_START = 'APPLICATIONS_LOADING_START';
export const APPLICATIONS_LOADING_END = 'APPLICATIONS_LOADING_END';
export const APPLICATIONS_LOADED = 'APPLICATIONS_LOADED';
export const APPLICATIONS_FAILED = 'APPLICATIONS_FAILED';
export const CLEAR_APPLICATIONS_COMPONENT_DATA = 'CLEAR_APPLICATIONS_COMPONENT_DATA';

export const FETCH_APPLICATION = 'FETCH_APPLICATION';
export const APPLICATION_LOADED = 'APPLICATION_LOADED';
export const APPLICATION_FAILED = 'APPLICATION_FAILED';
export const DELETE_APPLICATION = 'DELETE_APPLICATION';

export const FETCH_APPLICATION_VERSION = 'FETCH_APPLICATION_VERSION';
export const APPLICATION_VERSION_LOADING_START = 'APPLICATION_VERSION_LOADING_START';
export const APPLICATION_VERSION_LOADING_END = 'APPLICATION_VERSION_LOADING_END';

export const APPLICATION_VERSION_LOADED = 'APPLICATION_VERSION_LOADED';
export const APPLICATION_VERSION_FAILED = 'APPLICATION_VERSION_FAILED';

export const AWS_S3_SEM2_CONFIG = 'AWS_S3_SEM2_CONFIG';
export const AWS_S3_SEM2_CONFIG_LOADED = 'AWS_S3_SEM2_CONFIG_LOADED';
export const AWS_S3_SEM2_CONFIG_FAILED = 'AWS_S3_SEM2_CONFIG_FAILED';

export const FETCH_MAPS = 'FETCH_MAPS';
export const MAPS_LOADING_START = 'MAPS_LOADING_START';
export const MAPS_LOADING_END = 'MAPS_LOADING_END';
export const MAPS_LOADED = 'MAPS_LOADED';
export const MAPS_FAILED = 'MAPS_FAILED';

export const FETCH_MAP = 'FETCH_MAP';
export const MAP_LOADED = 'MAP_LOADED';
export const MAP_FAILED = 'MAP_FAILED';

export const CREATE_MAP = 'CREATE_MAP';
export const DELETE_MAP = 'DELETE_MAP';
export const DELETE_MAP_FAILED = 'DELETE_MAP_FAILED';
export const CREATE_MAP_LOADED = 'CREATE_MAP_LOADED';
export const CREATE_MAP_FAILED = 'CREATE_MAP_FAILED';

export const FETCH_MAP_VERSION = 'FETCH_MAP_VERSION';
export const MAP_VERSION_LOADING_START = 'MAP_VERSION_LOADING_START';
export const MAP_VERSION_LOADING_END = 'MAP_VERSION_LOADING_END';

export const MAP_VERSION_LOADED = 'MAP_VERSION_LOADED';
export const MAP_VERSION_FAILED = 'MAP_VERSION_FAILED';

export const FETCH_MAPNAMES_FOR_UPLOAD = 'FETCH_MAPNAMES_FOR_UPLOAD';
export const MAPNAMES_FOR_UPLOAD_LOADED = 'MAPNAMES_FOR_UPLOAD_LOADED';
export const MAPNAMES_FOR_UPLOAD_FAILED = 'MAPNAMES_FOR_UPLOAD_FAILED';

export const CREATE_FIRMWARE = 'CREATE_FIRMWARE';
export const CREATE_FIRMWARE_FAILED = 'CREATE_FIRMWARE_FAILED';

export const FETCH_FIRMWARES = 'FETCH_FIRMWARES';
export const FIRMWARES_LOADING_START = 'FIRMWARES_LOADING_START';
export const FIRMWARES_LOADING_END = 'FIRMWARES_LOADING_END';
export const FIRMWARES_LOADED = 'FIRMWARES_LOADED';
export const FIRMWARES_FAILED = 'FIRMWARES_FAILED';

export const FETCH_FIRMWARE = 'FETCH_FIRMWARE';
export const FIRMWARE_LOADED = 'FIRMWARE_LOADED';
export const FIRMWARE_FAILED = 'FIRMWARE_FAILED';

export const FETCH_FIRMWARE_VERSION = 'FETCH_FIRMWARE_VERSION';
export const FIRMWARE_VERSION_LOADING_START = 'FIRMWARE_VERSION_LOADING_START';
export const FIRMWARE_VERSION_LOADING_END = 'FIRMWARE_VERSION_LOADING_END';
export const FIRMWARE_VERSION_LOADED = 'FIRMWARE_VERSION_LOADED';
export const FIRMWARE_VERSION_FAILED = 'FIRMWARE_VERSION_FAILED';

export const FETCH_SETS = 'FETCH_SETS';
export const SETS_LOADING_START = 'SETS_LOADING_START';
export const SETS_LOADING_END = 'SETS_LOADING_END';
export const SETS_LOADED = 'SETS_LOADED';
export const SETS_FAILED = 'SETS_FAILED';

export const FETCH_TEST_SETS = 'FETCH_TEST_SETS';
export const TEST_SETS_LOADING_START = 'TEST_SETS_LOADING_START';
export const TEST_SETS_LOADING_END = 'TEST_SETS_LOADING_END';
export const TEST_SETS_LOADED = 'TEST_SETS_LOADED';
export const TEST_SETS_FAILED = 'TEST_SETS_FAILED';

export const GET_SET = 'GET_SET';
export const SET_LOADED = 'SET_LOADED';
export const SET_FAILED = 'SET_FAILED';

export const GET_TEST_SET = 'GET_TEST_SET';
export const TEST_SET_LOADED = 'TEST_SET_LOADED';
export const TEST_SET_FAILED = 'TEST_SET_FAILED';

export const FETCH_BUSINESS_REPORTS = 'FETCH_BUSINESS_REPORTS';
export const BUSINESS_REPORTS_LOADED = 'BUSINESS_REPORTS_LOADED';
export const BUSINESS_REPORTS_FAILED = 'BUSINESS_REPORTS_FAILED';

export const FETCH_AUDIT_LOG_REPORTS = 'FETCH_AUDIT_LOG_REPORTS';
export const AUDIT_LOG_REPORTS_LOADED = 'AUDIT_LOG_REPORTS_LOADED';
export const AUDIT_LOG_REPORTS_FAILED = 'AUDIT_LOG_REPORTS_FAILED';
export const CLEAR_AUDIT_LOG_REPORTS = 'CLEAR_AUDIT_LOG_REPORTS';

export const FETCH_OTA_LOCK_REPORTS = 'FETCH_OTA_LOCK_REPORTS';
export const OTA_LOCK_REPORTS_LOADED = 'OTA_LOCK_REPORTS_LOADED';
export const OTA_LOCK_REPORTS_FAILED = 'OTA_LOCK_REPORTS_FAILED';

export const GET_BUSINESS_APPLICATON_REPORTS = 'GET_BUSINESS_APPLICATON_REPORTS';
export const GET_BUSINESS_FIRMWARE_REPORTS = 'GET_BUSINESS_FIRMWARE_REPORTS';
export const GET_BUSINESS_MAP_REPORTS = 'GET_BUSINESS_MAP_REPORTS';

export const FETCH_TECHNICAL_REPORT = 'FETCH_TECHNICAL_REPORT';
export const TECHNICAL_REPORT_LOADED = 'TECHNICAL_REPORT_LOADED';
export const HIDE_TECHNICALREPORT_API_LOADING = 'HIDE_TECHNICALREPORT_API_LOADING';
export const SHOW_TECHNICALREPORT_API_LOADING = 'SHOW_TECHNICALREPORT_API_LOADING';
export const RESET_TECHNICAL_REPORT = 'RESET_TECHNICAL_REPORT';
export const TECHNICAL_REPORT_FAILED = 'TECHNICAL_REPORT_FAILED';
export const CLEAR_TECHNICAL_REPORT_DATA = 'CLEAR_TECHNICAL_REPORT_DATA';

export const FETCH_DATA_THRESHOLD_REPORTS = 'FETCH_DATA_THRESHOLD_REPORTS';
export const DATATHRESHOLD_REPORT_API_LOADING = 'DATATHRESHOLD_REPORT_API_LOADING';
export const DATATHRESHOLD_REPORTS_LOADED = 'DATATHRESHOLD_REPORTS_LOADED';
export const DATATHRESHOLD_REPORTS_FAILED = 'DATATHRESHOLD_REPORTS_FAILED';

export const FETCH_ANALYSIS_TOOL_REPORT = 'FETCH_ANALYSIS_TOOL_REPORT';
export const INSERT_ANALYSIS_TOOL_REPORT = 'INSERT_ANALYSIS_TOOL_REPORT';
export const ANALYSIS_REPORT_API_LOADING = 'ANALYSIS_REPORT_API_LOADING';
export const ANALYSIS_TOOL_REPORTS_LOADED = 'ANALYSIS_TOOL_REPORTS_LOADED';
export const ANALYSIS_TOOL_REPORTS_FAILED = 'ANALYSIS_TOOL_REPORTS_FAILED';
export const CLEAR_ANALYSIS_REPORT_DATA = 'CLEAR_ANALYSIS_REPORT_DATA';

export const FETCH_VEHICLE_SOFTWARE_DETAILS = 'FETCH_VEHICLE_SOFTWARE_DETAILS';
export const VEHICLE_SOFTWARE_DETAILS_LOADED = 'VEHICLE_SOFTWARE_DETAILS_LOADED';
export const VEHICLE_SOFTWARE_DETAILS_FAILED = 'VEHICLE_SOFTWARE_DETAILS_FAILED';

export const FETCH_METADATAS = 'FETCH_METADATAS';
export const METADATAS_LOADING_START = 'METADATAS_LOADING_START';
export const METADATAS_LOADING_END = 'METADATAS_LOADING_END';
export const METADATAS_LOADED = 'METADATAS_LOADED';
export const METADATAS_FAILED = 'METADATAS_FAILED';

export const FETCH_ALL_METADATAS = 'FETCH_ALL_METADATAS';
export const ALL_METADATAS_LOADED = 'ALL_METADATAS_LOADED';
export const ALL_METADATAS_FAILED = 'ALL_METADATAS_FAILED';

export const FETCH_ALL_METADATA_VALUES = 'FETCH_ALL_METADATA_VALUES';
export const ALL_METADATA_VALUES_LOADED = 'ALL_METADATA_VALUES_LOADED';
export const ALL_METADATA_VALUES_FAILED = 'ALL_METADATA_VALUES_FAILED';

export const FETCH_METADATA_VALUES = 'FETCH_METADATA_VALUES';
export const METADATA_VALUES_LOADING_START = 'METADATA_VALUES_LOADING_START';
export const METADATA_VALUES_LOADING_END = 'METADATA_VALUES_LOADING_END';
export const METADATA_VALUES_LOADED = 'METADATA_VALUES_LOADED';
export const METADATA_VALUES_FAILED = 'METADATA_VALUES_FAILED';

export const FETCH_METADATA_UNINITIALIZED_VALUES = 'FETCH_METADATA_UNINITIALIZED_VALUES';
export const METADATA_UNINITIALIZED_VALUES_LOADED = 'METADATA_UNINITIALIZED_VALUES_LOADED';
export const METADATA_UNINITIALIZED_VALUES_FAILED = 'METADATA_UNINITIALIZED_VALUES_FAILED';
export const FETCH_ALL_UPDATED_METADATA = 'FETCH_ALL_UPDATED_METADATA';
export const UPDATE_UNINITIALIZED_VALUES = 'UPDATE_UNINITIALIZED_VALUES';

export const FETCH_DENIED_ACCESS_REPORTS = 'FETCH_DENIED_ACCESS_REPORTS';
export const DENIED_ACCESS_REPORTS_LOADING_START = 'DENIED_ACCESS_REPORTS_LOADING_START';
export const DENIED_ACCESS_REPORTS_LOADING_END = 'DENIED_ACCESS_REPORTS_LOADING_END';

export const DENIED_ACCESS_REPORTS_LOADED = 'DENIED_ACCESS_REPORTS_LOADED';
export const DENIED_ACCESS_REPORTS_FAILED = 'DENIED_ACCESS_REPORTS_FAILED';

export const FETCH_USERS = 'FETCH_USERS';
export const USERS_LOADING_START = 'USERS_LOADING_START';
export const USERS_LOADING_END = 'USERS_LOADING_END';
export const USERS_LOADED = 'USERS_LOADED';
export const USERS_FAILED = 'USERS_FAILED';

export const FETCH_USER_ROLES = 'FETCH_USER_ROLES';
export const USER_ROLES_LOADED = 'USER_ROLES_LOADED';
export const USER_ROLES_FAILED = 'USER_ROLES_FAILED';

export const FETCH_INSTALLATION_STATUS_REPORTS = 'FETCH_INSTALLATION_STATUS_REPORTS';
export const INSTALLATION_STATUS_REPORTS_LOADED = 'INSTALLATION_STATUS_REPORTS_LOADED';
export const INSTALLATION_STATUS_REPORTS_FAILED = 'INSTALLATION_STATUS_REPORTS_FAILED';

export const FETCH_EXPRESSIONS = 'FETCH_EXPRESSIONS';
export const EXPRESSIONS_LOADED = 'EXPRESSIONS_LOADED';
export const EXPRESSIONS_FAILED = 'EXPRESSIONS_FAILED';

export const DOWNLOAD_MAPS = 'DOWNLOAD_MAP';
export const DOWNLOAD_MAPS_LOADED = 'DOWNLOAD_MAP_LOADED';
export const DOWNLOAD_MAPS_FAILED = 'DOWNLOAD_MAP_FAILED';
export const REGION = 'REGION';
export const DOWNLOAD_MAP_SHOW_LOADING = 'DOWNLOAD_MAP_SHOW_LOADING';
export const DOWNLOAD_MAP_HIDE_LOADING = 'DOWNLOAD_MAP_HIDE_LOADING';

export const FETCH_VEHICLE_STATUS_REPORTS = 'FETCH_VEHICLE_STATUS_REPORTS';
export const FETCH_VEHICLE_STATUS_REPORTS_LOADED = 'FETCH_VEHICLE_STATUS_REPORTS_LOADED';
export const FETCH_VEHICLE_STATUS_REPORTS_FAILED = 'FETCH_VEHICLE_STATUS_REPORTS_FAILED';

export const FETCH_SOFTWARE_UPDATE_CRITERIA = 'FETCH_SOFTWARE_UPDATE_CRITERIA';
export const SOFTWARE_UPDATE_CRITERIA_LOADED = 'SOFTWARE_UPDATE_CRITERIA_LOADED';
export const SOFTWARE_UPDATE_CRITERIA_LOADING_START = 'SOFTWARE_UPDATE_CRITERIA_LOADING_START';
export const SOFTWARE_UPDATE_CRITERIA_LOADING_FAILED = 'SOFTWARE_UPDATE_CRITERIA_LOADING_FAILED';

export const PASSWORD_RESET = 'PASSWORD_RESET';
export const OPEN_TENANT_DIALOG = 'OPEN_TENANT_DIALOG';
export const CLOSE_TENANT_DIALOG = 'CLOSE_TENANT_DIALOG';
export const SET_SELECTED_TENANT = 'SET_SELECTED_TENANT';

export const LOGIN_ERROR_AZURE = 'LOGIN_ERROR_AZURE';

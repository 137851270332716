import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Divider
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { closeTenantDialog, openTenantDialog, setSelectedTenants } from '../../actions/loginAction'; // Import your action to close the dialog

function TenantDialog() {
  const dispatch = useDispatch();
  const { isDialogOpen, tenants } = useSelector(state => state.loginReducer); // Get dialog state from Redux store
  const [selectedTenant, setSelectedTenant] = useState('');

  useEffect(() => {
    // Set the default value to the first tenant's value when component mounts
    if (tenants.length > 0) {
      setSelectedTenant(tenants[0].tenant);
    }
  }, [tenants]);

  const handleChange = event => {
    setSelectedTenant(event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      dispatch(closeTenantDialog());
    }
  };

  const handleConfirm = () => {
    // Handle confirm action here (e.g., dispatch selectedTenant)
    dispatch(setSelectedTenants(selectedTenant));
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleClose}
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } // Darker overlay color
      }}
    >
      <DialogTitle>Please select the tenant</DialogTitle>
      <Divider></Divider>
      <DialogContent>
        <RadioGroup
          value={selectedTenant}
          onChange={handleChange}
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}
        >
          {tenants.map(tenant => (
            <FormControlLabel
              key={tenant.id}
              value={tenant.tenant}
              control={<Radio />}
              label={
                tenant.tenant === 'gtt'
                  ? 'Volvo Trucks'
                  : tenant.tenant === 'rt'
                  ? 'Renault Trucks'
                  : tenant.tenant === 'vce'
                  ? 'Volvo CE'
                  : tenant.tenant === 'vbc'
                  ? 'Volvo Bus'
                  : tenant.tenant === 'penta'
                  ? 'Volvo Penta'
                  : tenant.tenant === 'trucks_br'
                  ? 'Brazil Trucks'
                  : tenant.tenant === 'trucks_na'
                  ? 'Trucks North America'
                  : tenant.tenant === 'mack'
                  ? 'Mack Trucks North America'
                  : tenant.tenant === 'vtna'
                  ? 'Volvo Trucks North America'
                  : tenant.tenant
              }
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <Divider></Divider>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default TenantDialog;

import { apiMiddleware } from '../utility/apiMiddleware';
import { appendTenant } from '../utility/appendTenant';
import {
  GET_META_DATAS,
  GET_META_DATA_VALUES,
  GET_META_DATA_UNINITIALIZED_VALUES,
  GET_METADATA_ALL,
  GET_METADATA_ALL_VALUES,
  getTenantDetails,
  GET_METADATA_VEHICLE_REPOSITORY,
  UPDATE_METADATA_PACKAGE_VERSION_DETAILS
} from './apis';

export const getMetaDatas = async () => {
  try {
    const response = await apiMiddleware(
      GET_META_DATAS + `?tenant=${localStorage.getItem('tenantHandle') || ''}`,
      'get'
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
// tenant info not required for qa env, only iot
export const getMatadataValues = async name => {
  let url = GET_META_DATA_VALUES.replace(':property', name);
  try {
    const response = await apiMiddleware(appendTenant(url, ['tenant']), 'get');
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
// tenant info not required for qa env, only iot
export const getMatadataUninitializedValues = async name => {
  let url = GET_META_DATA_UNINITIALIZED_VALUES.replace(':property', name);
  try {
    const response = await apiMiddleware(appendTenant(url, ['tenant']), 'get');
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllMetaDatas = async component => {
  console.log(component);
  const firmwareType = localStorage.getItem('firmwareType');
  const tenant = getTenantDetails();
  const url =
    firmwareType === 'swc' && component === 'firmware'
      ? GET_METADATA_VEHICLE_REPOSITORY
      : tenant === 'gtt' || tenant === 'rt'
      ? GET_METADATA_ALL + ',VEHICLE_REPOSITORY'
      : GET_METADATA_ALL;

  try {
    const response = await apiMiddleware(url + `?tenant=${tenant || ''}`, 'get');
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllMetaDataValues = async () => {
  try {
    const response = await apiMiddleware(appendTenant(GET_METADATA_ALL_VALUES, ['tenant']), 'get');
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

//TECH-166653
export const updateMetadataPackageExpressions = async packageExpressionsList => {
  try {
    const response = await apiMiddleware(
      appendTenant(UPDATE_METADATA_PACKAGE_VERSION_DETAILS, ['tenant']),
      'post',
      packageExpressionsList
    );
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return { statusCode: 400 };
  }
};

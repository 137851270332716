import axios from 'axios';
import { store } from '..';
import { getApiHeaderConfig } from '../common/components/ApiConfig';
import { apiMiddleware } from '../utility/apiMiddleware';
import { appendTenant } from '../utility/appendTenant';
import {
  GET_MAPS,
  getSemType,
  GET_MAP,
  GET_MAP_VERSION,
  CREATE_MAP,
  DELETE_MAP,
  GET_MAPNAMES_FOR_UPLOAD,
  getUserDetails
} from './apis';

const getTenantDetails = () => {
  const newState = store.getState();
  return newState.loginReducer.tenant.handle || localStorage.getItem('tenantHandle') || '';
  //return localStorage.getItem('tenantHandle') || '';
};

export const getMaps = async () => {
  const semType = getSemType();
  //const tenant = getTenantDetails();
  let semForApi = semType;
  // semForApi = (tenant === 'vce' && semType === 'sem2') ?'DCM1':semType;
  try {
    //const { data } = await axios.get(GET_MAPS + semForApi + '?tenant=' + tenant);
    const { data } = await apiMiddleware(appendTenant(GET_MAPS + semForApi, ['tenant']), 'get');
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getMapNamesFormUpload = async () => {
  try {
    //const { data } = await axios.get(GET_MAPNAMES_FOR_UPLOAD+ `?tenant=${localStorage.getItem('tenantHandle') || ''}`);
    const { data } = await apiMiddleware(appendTenant(GET_MAPNAMES_FOR_UPLOAD, ['tenant']), 'get');
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getMap = async name => {
  try {
    // const { data } = await axios.get(GET_MAP + name + '?tenant=' + getTenantDetails(), {
    //   headers: {
    //     tenant: getTenantDetails(),
    //     userName: localStorage.getItem('user')
    //   }
    // });
    const { data } = await apiMiddleware(
      appendTenant(GET_MAP + name, ['tenant']),
      //GET_MAP + name + '?tenant=' + getTenantDetails(),
      'get',
      {},
      {
        headers: {
          tenant: getTenantDetails(),
          userName: getUserDetails() //localStorage.getItem('user')
        }
      }
    );
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getMapVersion = async name => {
  let url = GET_MAP_VERSION.replace(':name', name);
  //url = url + `?tenant=${localStorage.getItem('tenantHandle') || ''}`;
  try {
    //const { data } = await axios.get(url);
    const { data } = await apiMiddleware(appendTenant(url, ['tenant']), 'get');
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const create = async map => {
  try {
    // const { data, status } = await axios.post(
    //   CREATE_MAP + `?tenant=${localStorage.getItem('tenantHandle') || ''}`,
    //   map,
    //   getApiHeaderConfig('userName&tenant')
    // );

    const response = await apiMiddleware(
      //CREATE_MAP + `?tenant=${localStorage.getItem('tenantHandle') || ''}`,
      appendTenant(CREATE_MAP, ['tenant']),
      'post',
      map,
      getApiHeaderConfig('userName&tenant')
    );

    // if (statusCode === 409 || statusCode === 404) {
    //   return { flag: false };
    // }
    if (response.errorFlag) {
      return { flag: false };
    }
    return { flag: true, data: response.data };
  } catch (err) {
    return { flag: false, err };
  }
};

export const deleteMap = async name => {
  try {
    // const { data, status } = await axios.delete(
    //   DELETE_MAP.replace(':name', name) + `?tenant=${localStorage.getItem('tenantHandle') || ''}`,
    //   { ...getApiHeaderConfig('userName') }
    // );

    const response = await apiMiddleware(
      //DELETE_MAP.replace(':name', name) + `?tenant=${localStorage.getItem('tenantHandle') || ''}`,
      appendTenant(DELETE_MAP.replace(':name', name), ['tenant']),
      'delete',
      {},
      { ...getApiHeaderConfig('userName') }
    );

    if (response.errorFlag) {
      return { flag: false };
    }
    if (response.statusCode == 200 && response.data.deletedStatus == 'FALSE') {
      return { flag: false };
    }
    return { flag: true, data: response.data };
  } catch (err) {
    return { flag: false, err };
  }
};

import {
  LOGIN,
  LOGOUT,
  REFRESH_CREDENTIALS,
  UPDATE_SEMS_CLIENT,
  UPDATE_SEMS_TYPE,
  OPEN_TENANT_DIALOG,
  CLOSE_TENANT_DIALOG,
  SET_SELECTED_TENANT
} from '../constants';

export const login = user => {
  return {
    type: LOGIN,
    user
  };
};

export const logout = (payload = '') => {
  return {
    type: LOGOUT,
    payload
  };
};

export const updateSemsClient = data => {
  return {
    type: UPDATE_SEMS_CLIENT,
    data
  };
};

export const updateSems = data => {
  return {
    type: UPDATE_SEMS_TYPE,
    data
  };
};

export const refreshCredentials = () => {
  return {
    type: REFRESH_CREDENTIALS
  };
};
export const openTenantDialog = data => ({
  type: OPEN_TENANT_DIALOG,
  payload: data
});

export const closeTenantDialog = () => ({
  type: CLOSE_TENANT_DIALOG
});

export const setSelectedTenants = tenant => ({
  type: SET_SELECTED_TENANT,
  payload: tenant
});
